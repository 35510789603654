@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@400;500;600&family=Miriam+Libre:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@400:500&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

#root > div[data-rk] {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root > div[data-rk] nav + div {
  flex-grow: 1;
}

@layer components {
  .gradient-border-qf {
    position: relative;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    z-index: 0;
    overflow: hidden;
  }

  .gradient-border-qf::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 1rem;
    padding: 2px;
    background: linear-gradient(180deg, #ADEDE5 0%, #5CC8BB 100%);
    z-index: -1;
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
            mask-composite: exclude; 
  }

  .gradient-border-direct {
    position: relative;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    z-index: 0;
    overflow: hidden;
  }

  .gradient-border-direct::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 1rem;
    padding: 2px;
    background: linear-gradient(180deg, #FFEFBE 0%, #FFD555 100%);
    z-index: -1;
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
            mask-composite: exclude; 
  }
}

@layer base {
  html {
    @apply text-grey-500;
    font-family: Libre Franklin;
  }

  h1 {
    @apply text-7xl;
    font-family: Miriam Libre;
  }

  h2 {
    @apply text-5xl;
    font-family: Miriam Libre;
  }

  h3 {
    @apply text-3xl;
    font-family: Miriam Libre;
  }

  h4 {
    @apply text-2xl;
    font-family: Miriam Libre;
  }

  h5 {
    @apply text-base;
    font-family: Miriam Libre;
  }

  h6 {
    @apply text-sm;
    font-family: Miriam Libre;
  }

  p {
    @apply text-base;
  }
}
